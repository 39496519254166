<template>
  <div class="fb-cookie">
    <el-form label-width="80px" style="min-width:700px">
      <el-form-item label="userid">
        <el-input v-model="userId" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item label="sess">
        <el-input v-model="sess" placeholder="TODO"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSetCookie">设置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { crawlerApi } from "@/api/crawlerApi";
import msgLogger from "@/util/msgLogger";
export default {
  data() {
    return {
      sess:
        "iY6mqRc/Xh/kZf2jBJIY7/vCisjWvcQpXVbTfBBBBMgRI1FUUXdvTS7COhvnJGJrnQtV4J6mgPB9mlxylQ6yTawjIbkZcavctXU42YXWjIk=",
      userId: "52226418",
    };
  },
  mounted() {},
  methods: {
    onSetCookie() {
      if (!this.userId) {
        msgLogger.warn("userId 不允许为空");
        return;
      }
      if (!this.sess) {
        msgLogger.warn("sess 不允许为空");
        return;
      }
      crawlerApi
        .setFbCookie(this.userId, encodeURIComponent(this.sess))
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0 && data) {
            msgLogger.done("设置成功");
          } else {
            msgLogger.error("出错了:" + ret.msg);
          }
        })
        .catch((err) => {
          console.log("err", err);
          msgLogger.error("出错了" + err);
        });
    },
  },

  watch: {},
};
</script>

<style lang="less" scoped>
.paper {
  text-align: left;
}
.solution {
  text-align: left;
}
</style>
